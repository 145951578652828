// ignore-string-externalization

import React from 'react';
import { NextPage, NextPageContext } from 'next';
import {
  templateLoader,
  TemplateType,
  Template,
} from '../../features/Template';
import { NotFound } from '../../features/Layout';

export const ArticlePage: NextPage<{
  template?: TemplateType | null;
  preview?: boolean;
}> = ({ template, preview }) => {
  if (!template) {
    return <NotFound />;
  }

  if (!preview && !publishedByDate(template)) {
    return <NotFound />;
  }

  return (
    <Template
      {...template}
      canonicalUrl={`/blog/${template.urlSlug}`}
      preview={preview}
    />
  );
};

ArticlePage.getInitialProps = async ({
  smartlingLanguage,
  query,
  preview,
}: NextPageContext & { preview?: boolean; smartlingLanguage: string }) => {
  const urlSlug: string =
    typeof query.urlSlug === 'string'
      ? query.urlSlug
      : (query.urlSlug as string[]).join('/');

  const template = await templateLoader.load({
    contentType: 'articlePage',
    urlSlug,
    smartlingLanguage,
    preview,
  });

  return {
    template,
    preview,
  };
};

function publishedByDate(template: TemplateType): boolean {
  if (template.releaseDate) {
    const parsedReleaseDate: Date = new Date(template.releaseDate);
    return parsedReleaseDate ? parsedReleaseDate < new Date() : false;
  }
  return true;
}

export default ArticlePage;
